import React from 'react'
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export interface Props {
    settings: Settings;
    children?: any;
}

function SlickSlider({ settings, children }: Props) {

    return (
        <Slider {...settings}>
            {children}
        </Slider>
    )
}

export default SlickSlider